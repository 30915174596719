<template>
  <div id="app" class="container" style="background-color: #f7fafc">
    <Loader1 v-if="loader" />

    <h1 class="text-center"></h1>
    <div class="row">
      <div class="col">
        <projects-table title="Light Table"></projects-table>
      </div>
    </div>
    <div></div>

    <div class="container-fluid mt-6" style="background-color: white">
      <div class="row">
        <div class="col-md-4 mb-2 mt-2 pl-1">
          <!-- <router-link to="/sub-level/add/"> -->
          <base-button
            @click="add"
            type="primary"
            style="
              border-radius: 26px;
              font-size: 14px;
              background: #1f7dad;
              border-color: #1f7dad;
            "
            class="my-2 ml-2"
            >Add</base-button
          >
          <!-- </router-link> -->
        </div>

        <div class="col-md-8 text-right mt-2 mb-2 p-0">
          <!-- <button
            class="btn btn-dark statusBtn my-2 mr-2 btnst"
            style=""
            @click="activate"
            id="activate"
          >
            Active
          </button>
          <button
            class="btn btn-secondary statusBtn my-2 mr-2 btnst"
            @click="deactivate"
            id="deactivate"
          >
            Deactive
          </button> -->

          <button
            class="btn btn-danger statusBtn my-2 mr-2 btnst"
            @click="deletee"
            id="delete"
          >
            Delete
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <h4 style="font-size: 1.5rem; margin-bottom: -11px">
            Challenge ({{ levelLenght }})
          </h4>
        </div>
      </div>
      <br />
      <br />
      <div class="row">
        <div class="col-sm-12 col-md-6">
          <div id="tickets-table_length" class="dataTables_length">
            <label class="d-inline-flex align-items-center">
              Show
              <b-form-select
                v-model="perPage"
                size="sm"
                :options="pageOptions"
              ></b-form-select>
              entries
            </label>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 float-right" style="margin-top: 3px">
          <div
            id="tickets-table_filter"
            class="dataTables_filter text-md-right float-right"
          >
            <label class="d-inline-flex align-items-center">
              Search:
              <b-form-input
                v-model="filter"
                type="search"
                placeholder="Search..."
                class="form-control form-control-sm ml-2"
              ></b-form-input>
            </label>
          </div>
        </div>
      </div>
      <div class="col-12 p-0">
        <div class="table-responsive">
          <form
            method="post"
            @submit.prevent="changeStatus"
            @keydown="form.onKeydown($event)"
            enctype="multipart/form-data"
          >
            <input type="hidden" v-model="status" name="status" id="" />

            <b-table
              :items="challenges"
              :fields="fields"
              responsive="sm"
              :per-page="perPage"
              :current-page="currentPage"
              :sort-by.sync="sortBy"
              ref="selectableTable"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              show-empty
              empty-text="No record found"
              empty-filtered-text="No search result found"
              @filtered="onFiltered"
            >
              <template #head(id)>
                <input
                  type="checkbox"
                  v-model="allSelected"
                  @change="selectAll"
                />
              </template>

              <template v-slot:cell(id)="challenges">
                <input
                  type="checkbox"
                  v-model="selected"
                  @change="statusCheck"
                  :value="challenges.item.id"
                  name="id[]"
                  number
                />
              </template>

                <template v-slot:cell(image1)="challenges">
                <img
                  style="width: 50px !important"
                  class="imgg"
                  :src="imgUrl + '/images/' + challenges.item.image1"
                  alt="..."
                />
              </template>
                  <template v-slot:cell(image2)="challenges">
                <img
                  style="width: 50px !important"
                  class="imgg"
                  :src="imgUrl + '/images/' + challenges.item.image2"
                  alt="..."
                />
              </template>
                  <template v-slot:cell(image3)="challenges">
                <img
                  style="width: 50px !important"
                  class="imgg"
                  :src="imgUrl + '/images/' + challenges.item.image3"
                  alt="..."
                />
              </template>
                  <template v-slot:cell(image4)="challenges">
                <img
                  style="width: 50px !important"
                  class="imgg"
                  :src="imgUrl + '/images/' + challenges.item.image4"
                  alt="..."
                />
              </template>

            
         <template v-slot:cell(category)="challenges">
            <span>{{challenges.item.category.name}}</span>
              </template>
              <template v-slot:cell(challenge)="challenges">
                <router-link
                  :to="{
                    name: 'View challenge',
                    params: { id: challenges.item.id },
                  }"
                >
                  <span style="color: black">{{ 0 }}</span>
                </router-link>
              </template>
              <template v-slot:cell(action)="challenges">
                <router-link
                  :to="{
                    name: 'Edit challenge',
                    params: { id: challenges.item.id },
                  }"
                >
                  <font-awesome-icon
                    style="font-size: 16px; padding: 0 4px"
                    icon="fa-solid fa-pencil"
                  />
                </router-link>

                <a
                  @click="deleteLevel(challenges.item.id)"
                  style="font-size: 16px; color: #dc3545"
                >
                  <font-awesome-icon
                    style="font-size: 16px; padding: 0 4px"
                    icon="fa-solid fa-trash-can"
                  />
                </a>
              </template>
            </b-table>
          </form>

          <br />
        </div>
      </div>
      <div class="col-sm-12 col-md-6 float-right p-0" style="margin-top: 13px">
        <div class="dataTables_paginate paging_simple_numbers float-right mb-3">
          <ul class="pagination pagination-rounded mb-0">
            <!-- pagination -->
            <b-pagination
              v-model="currentPage"
              :per-page="perPage"
              :total-rows="rows"
            ></b-pagination>
          </ul>
        </div>
      </div>
    </div>
    <br />
    <br />
    <br />
  </div>
</template>

<script>
import "bootstrap/dist/css/bootstrap.min.css";
import "datatables.net-bs4";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import Form from "vform";
import Swal from "sweetalert2";
import axios from "axios";
import $ from "jquery";
import Loader1 from "../Loader1";

// import "jszip";
// import "pdfmake";
// import "datatables.net-buttons-bs4";
// import "datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css";
// import "datatables.net-buttons/js/buttons.colVis";
// import "datatables.net-buttons/js/buttons.flash";
// import "datatables.net-buttons/js/buttons.html5";
// import "datatables.net-buttons/js/buttons.print";

export default {
  components: {
    Loader1,
  },
  data() {
    return {
      challenges: [],
      char_names: [],
      errors: [],
      record: {},
      alertText: "",
      alertRowNum: "",
      alertSuccess: "",
      checkedNames: [],
      alertDuplicate: "",
      alert: false,
      perPage: 10,
      currentPage: 1,
      totalRows: 1,
      pageOptions: [10, 25, 50, 100],
      sortBy: "id",
      sortDesc: true,
      filter: null,
      filterOn: [],
      isDelete: true,
      items: "",
      levelLenght: null,
      selected: [],
      allSelected: false,
      countSubLevel: null,
      count: 0,
      status: "",
      loader: false,

      imgUrl: this.$appName,

      form: new Form({
        status: "",
        id: "",
      }),

      fields: [
        { key: "id", label: "Id", tdClass: "align-middle" },
        { key: "name", label: "Name", tdClass: "align-middle" },
        { key: "answer", label: "Answer", tdClass: "align-middle" },
        { key: "category", label: "Category", tdClass: "align-middle" },
        { key: "image1", label: "ImageA", tdClass: "align-middle" },
        { key: "image2", label: "ImageB", tdClass: "align-middle" },
        { key: "image3", label: "ImageC", tdClass: "align-middle" },
        { key: "image4", label: "ImageD", tdClass: "align-middle" },

        { key: "action", label: "Action", tdClass: "align-middle" },
      ],
    };
  },

  mounted() {

    axios
      .get(this.$appName + `/api/challenge/edit/${this.$route.params.id}`)
      .then((res) => {
        //pushing data to items that will show inside table
        this.challenges = res.data;
        console.log(this.challenges);
        this.levelLenght = res.data.length;
        this.rows = res.data.length;
      });
  },
  methods: {
    add() {
      localStorage.removeItem("sublevelID");
      localStorage.setItem("sublevelID", this.$route.params.id);
      this.$router.push("/challenge-add");
    },
    deleteLevel(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire("Deleted!", "Your file has been deleted.", "success");
          //  location.reload();

          axios
            .delete(this.$appName + `/api/challenge/Challenge/${id}`)
            .then((response) => {
              console.log(response);
              let i = this.challenges.map((data) => data.id).indexOf(id);
              this.challenges.splice(i, 1);
              this.isDelete = false;
              // location.reload();
            });
        }
      });
    },
    async selectAll() {
      if (this.allSelected) {
        this.checkedNames = this.$refs.selectableTable.paginatedItems.map(
          (u) => u.id
        );
        this.selected = this.checkedNames;
        // const selected = this.challenges.map((u) => u.id);
        // this.selected = selected;
        this.count = this.challenges.length;
      } else {
        this.selected = [];
        this.count = 0;
      }
    },
    // async statusCheck(event) {
    //   if (event.target.checked == true) {
    //     this.count = this.count + 1;
    //   } else {
    //     this.count = this.count - 1;
    //   }
    // },

    async deletee() {
      // if (this.count > 0) {
      this.status = "delete";
      this.changeStatus();
      // } else {
      //   alert("Please select atleast one record");
      // }
    },

    changeStatus() {
      if (this.selected != "") {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        }).then((result) => {
          if (result.isConfirmed) {
            this.loader = true;
            let data = new FormData();
            data.append("status", this.status);
            data.append("id", this.selected);
            axios
              .post(this.$appName + "/api/challenge/statusChange", data)
              .then((response) => {
                console.log(response);
                this.isDelete = false;
                this.loader = false;

                location.reload();
              });
          }
        });
      } else {
        alert("Please select atleast one record");
      }
    },

    handleOk(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();
      // Trigger submit handler
      this.submitUtlity();
    },

    methodClose() {
      location.reload();
    },
  },
};
</script>

<style>
.btnst {
  border-radius: 26px;
  font-size: 15px;
}
.imgg {
  width: 60px !important;
  max-width: 60px !important;
  padding: 0.25rem;
  background-color: #f8f9fe;
  border: 1px solid #dee2e6;
  border-radius: 0.375rem;
  box-shadow: 0 1px 2px rgb(0 0 0 / 8%);
}

.btnn {
  color: gray;
  background-color: white;
  padding: 8px 12px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
}

.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.3) !important;
}

.page-item.active .page-link {
  background: #1f7dad;
  border-color: #1f7dad;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.swal2-icon.swal2-warning {
  border-color: #dc3545;
  color: #dc3545;
}
.swal2-styled.swal2-cancel {
  background: #dc3545 !important;
  border-radius: 26px !important;
}
.swal2-styled.swal2-confirm {
  border-radius: 26px !important;
  background: #1f7dad !important;
}
.import_utlity {
  background: #1f7dad;
  color: white;
  border-color: #1f7dad;
}
.swal2-styled.swal2-default-outline:focus {
  box-shadow: unset !important;
}
.import_utlity:hover {
  background: #1f7dad;
  color: white;
  border-color: #1f7dad;
}
.import_utlity:focus {
  background: #1f7dad;
  box-shadow: none !important;
  color: white;
  border-color: #1f7dad !important;
}

.table thead th {
  font-weight: 700;
}
select#__BVID__36 {
  margin-right: 3px;

  margin-left: 3px;
}
.btn-secondary {
  border-radius: 26px !important;
}
.btn-primary {
  border-radius: 26px !important;
}
.modal-title {
  font-size: 1.5rem;
}

.swal2-styled.swal2-confirm:focus {
  box-shadow: unset;
}
.custom-select {
  margin-left: 3px;
  margin-right: 3px;
}
a.h4.mb-0.text-white.text-uppercase.d-none.d-lg-inline-block.active.router-link-active {
  display: none !important;
}
</style>
